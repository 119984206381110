import React from "react";
import Search from "./../components/Search";
import { Grid, Box, Skeleton } from "@mui/material";
import { useGameStateStore } from "../stores/gameStateStore";
import CoachButton from "./CoachButton";

const CoachesGrid = () => {
  const coachList = useGameStateStore((state) => state.coachList);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Search
        sx={{
          height: "150px",
          borderRadius: "25px 25px 0 0",
          /*           boxShadow:
            "0px 0px 16px 8px rgba(0, 0, 0, 0.2), 0px 0px -16px -8px rgba(0, 0, 0, 0.2)", */
        }}
      ></Search>
      <Box
        sx={{
          marginTop: "-50px",
          padding: "24px",
          backgroundColor: "white",
          borderRadius: "25px 25px 0 0",
          /*           boxShadow:
            "0px 0px 16px 8px rgba(0, 0, 0, 0.2), 0px 0px -16px -8px rgba(0, 0, 0, 0.2)", */
        }}
      >
        <Grid
          sx={{
            height: "calc(100% - 110px)",
            overflowY: "auto",
            bottom: "0",
          }}
          container
          gap={2}
          justifyContent="space-between"
          alignContent={"flex-start"}
        >
          {coachList.length > 0
            ? coachList.map((coach, idx) => {
                return (
                  <Grid
                    key={idx}
                    sx={{
                      maxWidth: {
                        xs: " calc(50% - 8px)",
                        sm: " calc(50% - 8px)",
                        md: " calc(25% - 16px)",
                        lg: " calc(25% - 16px)",
                      },
                      width: "100%",
                    }}
                    item
                  >
                    <CoachButton coach={coach} />
                  </Grid>
                );
              })
            : Array(4)
                .fill("")
                .map((_, idx) => (
                  <Grid
                    key={idx}
                    sx={{
                      maxWidth: {
                        xs: " calc(50% - 8px)",
                        sm: " calc(50% - 8px)",
                        md: " calc(25% - 16px)",
                        lg: " calc(25% - 16px)",
                      },
                      width: "100%",
                    }}
                    item
                  >
                    <Skeleton variant="rounded" height={105} />
                  </Grid>
                ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default CoachesGrid;
