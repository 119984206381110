import React from "react";
import { Box, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { Coach } from "../models";
import { useGameStateStore } from "../stores/gameStateStore";

type CoachCardProps = {
  coach: Coach;
};

const CoachCard = ({ coach }: CoachCardProps) => {
  const currentOffer = useGameStateStore(state => state.currentOffer);

  return (
    <Box
      sx={{
        backgroundColor: "rgb(234, 234, 234)",
        padding: "12px",
        borderRadius: "10px",
      }}
    >
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography
          textAlign={"left"}
          sx={{ fontSize: "1.125rem", fontWeight: "bold" }}
        >
          {`Coach ${coach.id}`}
        </Typography>
        <Box
          sx={{
            backgroundColor:
              coach.id === currentOffer?.coach?.id ? "red" : "inherit",
            height: "16px",
            width: "16px",
            borderRadius: "100%",
          }}
        ></Box>
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Box>
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              color: "rgba(79, 89, 243, 1)",
            }}
          >
            {coach.budget.toString()}
          </Typography>
          <Typography sx={{ fontSize: "0.75rem", fontWeight: "bold" }}>
            BUDGET
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              color: "rgba(79, 89, 243, 1)",
            }}
          >
            {coach.slot.toString()}
          </Typography>
          <Typography sx={{ fontSize: "0.75rem", fontWeight: "bold" }}>
            SLOT
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default CoachCard;
