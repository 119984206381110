import { Coach } from "../models";
import { MAX_RELAUNCHES } from "../utils/constants";
import { OfferStateType } from "../stores/gameStateStore";

export const useSimulationService = () => {
  /**
   * @param maxOffer The max value that the coach can offer
   * @returns A number that represents the value of the offer
   * @description This function generates the incremental bidding that this coach can do. Each time the coach bids, his aggressiveness increases following a logarithmic scale
   */
  const calculateCoachOffer = (
    maxOffer: number,
    currentOffer: number | undefined,
    aggr: number
  ): number => {
    //const maxOffer = coach.getPriceCapWhere(currentOffer)!.priceCap;
    if (maxOffer > (currentOffer || 0)) {
      //let a = Math.log2(MAX_RELAUNCHES + 1);
      //let b = maxOffer / a;
      const offers = generateOffers(maxOffer, aggr);
      const filtered = offers.filter((o) => o > (currentOffer || 0));
      const offer = filtered.pop();
      //console.log(coach.id, offers, offer, maxOffer);
      return offer || -1;
    } else {
      return -1;
    }
  };
  /**
   * Funzione che genera la curva dentro Settings
   * @param maxOffer
   * @param aggr
   * @returns
   */
  const generateOffers = (maxOffer: number, aggr = 1.5) => {
    const offers = [];
    let a = Math.log2(MAX_RELAUNCHES + aggr);
    let b = maxOffer / a;
    for (let i = 1; i < MAX_RELAUNCHES; i++) {
      offers.unshift(Math.log2(i + aggr) * b);
      /*offers.unshift(
        Math.pow(i, exp) * (maxOffer / Math.pow(MAX_RELAUNCHES, exp))
      );*/
    }
    offers.unshift(maxOffer);
    return offers.map(Math.floor);
  };

  const simulateOffer = (
    coachList: Coach[],
    currentOffer: OfferStateType | null,
    botAggr?: number
  ): OfferStateType | undefined => {
    for (const coach of coachList) {
      if (coach.id !== currentOffer?.coach!.id) {
        //const priceCap: number = coach.getPriceCapWhere(currentOffer)!.priceCap; //TODO: riguardare
        const offer = calculateCoachOffer(
          coach.getPriceCapWhere(currentOffer)!.priceCap,
          currentOffer?.offer,
          botAggr!
        );
        console.log(coach.id, offer);
        if (offer > -1 && offer !== currentOffer?.offer && coach.slot) {
          //coach.slot > 0 posso scriverlo anche senza " > 0 " perche quando vale 0 è cmq false
          /*let offer =  currentOffer?.offer || 0) + 1;
          offer += Math.floor(
            Math.random() * (priceCap - offer > 4 ? 4 : priceCap - offer)
          );*/
          return {
            coach,
            offer,
          };
        }
      }
    }
  };

  return {
    calculateCoachOffer,
    generateOffers,
    simulateOffer,
  };
};
