import {
    Slider,
    Stack,
    Typography,
} from "@mui/material";
import React from "react";
import { useSettingsStore } from "../../stores";
export const TimoutOption = () => {

    const { timeoutSeconds, setTimeoutSeconds } = useSettingsStore();


    const handleChangeSlider = (event: any) => {
        setTimeoutSeconds(event.target.value);
    };

    return (<>
        <Typography
            align="left"
            variant="h5"
            sx={{
                width: "100%",
                fontWeight: "bold",
                marginTop: "20px",
                color: "rgba(50, 58, 164, 1)",
            }}
        >
            Timeout seconds: {timeoutSeconds}
        </Typography>
        <Stack
            direction={"row"}
            sx={{
                width: "100%",
                margin: "20px auto",
                alignItems: "center",
            }}
        >
            <Slider
                valueLabelDisplay="auto"
                value={timeoutSeconds}
                onChange={handleChangeSlider}
                step={1}
                marks
                min={1}
                max={10}
            />
        </Stack>
    </>)
}