import create from "zustand";
import { Player } from "../models";

export type SettingsStoreProps = {
  botSpeed: number;
  setBotSpeed: (value: number) => void;
  botAggr: number;
  setBotAggr: (value: number) => void;
  timeoutSeconds: number;
  setTimeoutSeconds: (value: number) => void;
};

export const useSettingsStore = create<SettingsStoreProps>()((set) => ({
  botSpeed: 10,
  setBotSpeed: (botSpeed) => {
    set({ botSpeed });
  },

  botAggr: 0,
  setBotAggr: (botAggr) => {
    set({ botAggr });
  },

  timeoutSeconds: 5,
  setTimeoutSeconds: (timeoutSeconds) => {
    set({ timeoutSeconds });
  },
}));
