import React, { useEffect, useState } from "react";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { MAX_CHART_OFFER, MAX_RELAUNCHES } from "../../utils/constants";
import { useSimulationService } from "../../services";
import { Box, Slider, Stack, Typography } from "@mui/material";
import { useSettingsStore } from "../../stores";
import { SliderField } from "../../components/input";

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


export const options: ChartOptions<any> = {
  responsive: true,
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: false,
    },
  },
  scales: {
    yAxis: {
      min: 0,
      max: MAX_CHART_OFFER,
      display: true
    },
  }
};


export const BotAggrOption = () => {

  const simulationService = useSimulationService()
  const [data, setData] = useState<ChartData<any>>({
    datasets: []
  })
  const { botAggr, setBotAggr } = useSettingsStore()

  useEffect(() => {
    generateData()
  }, [botAggr])

  const generateData = () => {
    const labels = Array.from({ length: MAX_RELAUNCHES }, (_, i) => i + 1)
    const data = simulationService.generateOffers(MAX_CHART_OFFER, botAggr).reverse()
    setData({
      labels,
      datasets: [
        {
          data: data,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',

        },
      ],
    });
  }

  return (
    <Box>
      <Stack sx={{
        height: 200,
        position: "relative"
      }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Line options={options} data={data} />
      </Stack>
      <SliderField
        label="Bot aggressivity"
        value={botAggr}
        setValue={setBotAggr}
        min={0}
        max={10}
        step={0.25}
      />
    </Box>
  );
};

