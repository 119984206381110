export class Player {
  id: number;
  name: string;
  level: number;
  paid?: number;

  constructor(id: number, name: string, level: number) {
    this.id = id;
    this.name = name;
    this.level = level;
  }
}
