import { Box, Button, Typography } from "@mui/material";
import RenderImage from "../components/BoxAuctionComponent";
import { Auction } from "../models/auction";

type Auctionprops = {
  auction: Auction;
  onClick: () => void;
};

export default function BoxAuction({ auction, onClick }: Auctionprops) {
  //TODO sistemare il CSS della HomePage da questi componenti

  const firstLetterAuctionType = auction.name.charAt(0).toUpperCase();

  return (
    <Button
      sx={{
        marginBottom: "20px",
        padding: "10px 30px",
        borderRadius: "30px",
        backgroundColor: "white",
        color: "black",
        display: "table-row",
        marginTop: "15px",
        position: "relative",
        overflow: "hidden",
      }}
      onClick={onClick}
    >
      <Typography
        sx={{
          textAlign: "left",
          fontSize: "18px",
          padding: "10px 0px",
          fontWeight: "bold",
        }}
        variant="h3"
      >
        {auction.name}
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplate: "45px 45px / repeat(2, 1fr)",
        }}
      >
        <RenderImage
          src={"/AuctionModeIcon/dollar-symbol.png"}
          data={auction.coachMoney}
        />
        <RenderImage
          src={"/AuctionModeIcon/multiple-users-silhouette.png"}
          data={auction.playersNumber}
        />
        <RenderImage
          src={"/AuctionModeIcon/user.png"}
          data={auction.coachNumber}
        />
        <RenderImage
          src={"/AuctionModeIcon/layer.png"}
          data={auction.slotNumber}
        />
      </Box>
      <Box
        sx={{
          position: "absolute",
          left: "100%",
          backgroundColor: "red",
          top: "75px",
          width: "50px",
          height: "200px",
        }}
      >
        <Typography
          sx={{
            fontSize: "260px;",
            fontFamily: "AuctionFont",
            opacity: 0.3,
            lineHeight: 0,
            position: "relative",
            left: "-130px",
            fontWeight: "bold",
            textAlign: "right",
          }}
        >
          {firstLetterAuctionType}
        </Typography>
      </Box>
    </Button>
  );
}
