import create from "zustand";
import { Auction } from "../models";

export type AuctionsStoreProps = {
  auctionTypeList: Auction[];
  setAuctionTypeList: (value: Auction[]) => void;
};

export const useAuctionsStore = create<AuctionsStoreProps>()((set) => ({
  auctionTypeList: [],
  setAuctionTypeList: (value) => set((_) => ({ auctionTypeList: value })),
}));
