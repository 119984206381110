import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MenuItem from "@mui/material/MenuItem";
import { CommonProps } from "@mui/material/OverridableComponent";
import { Menu, SxProps, Theme } from "@mui/material";
import { RouteItem } from "../utils/routes";
import { useState } from "react";
type FantaNavbarProps = {
  showBack?: boolean;
  title: string;
  items?: RouteItem[];
  isDisabled?: boolean;
  sx?: SxProps<Theme>;
} & CommonProps;

export default function FantaNavbar({
  showBack,
  title,
  items,
  isDisabled,
  ...rest
}: FantaNavbarProps) {
  const navigate = useNavigate();
  showBack = showBack ?? true; // se showBack non è undefined o null sarà true

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenuItem = (item: RouteItem, idx: number) => {
    return (
      <MenuItem
        sx={{
          size: "large",
          color: "inherit",
        }}
        key={`menu_${idx}`}
        onClick={() => {
          handleClose()
          navigate(item.route)
        }
        }
      >
        {item.label}
      </MenuItem>
    )
  }
  return (
    <Box
      {...rest}
      sx={{ flexGrow: 1, color: "inherit", ...rest.sx }}
      boxShadow="none"
    >
      <AppBar position="static" sx={{ color: "inherit" }}>
        <Toolbar
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingInline: "0px",
            color: "inherit",
          }}
        >
          <MenuItem sx={{ paddingLeft: "0", fontWeight: "bold" }}>
            {showBack && (
              <IconButton
                sx={{
                  size: "large",
                  paddingLeft: "0",
                  color: "inherit",
                  paddingRight: "15px",
                }}
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon />
              </IconButton>
            )}
            <Typography
              variant="h6"
              component="div"
              color="inherit"
              fontWeight="bold"
            >
              {title}
            </Typography>
          </MenuItem>
          {(items?.length || 0) !== 0 && <>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              disabled={isDisabled}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {items?.map(renderMenuItem)}
            </Menu>
          </>
          }
        </Toolbar>
      </AppBar>
    </Box>
  );
}
