import { Box, Typography } from "@mui/material";

type RenderImageProps = {
    src: string;
    data: number;
}

export default function RenderImage({src, data}: RenderImageProps) {
    return (
      <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
          <img className="ButtonAuctionImage" src={src} alt=""/>
        <Typography variant="h3" sx={{fontSize: "20px", color: "rgba(79, 89, 243, 1)", fontWeight: "bold"}}>{data}</Typography>
        </Box>
    )
}