import React, { useEffect, useRef, useState } from "react";
import CoachesGrid from "../components/CoachesGrid";
import FantaNavbar from "../components/FantaNavbar";
import Timer from "../components/Timer";
import RelaunchButton from "../components/RelaunchButton";
import { Box, Button, Stack } from "@mui/material";
import { OfferStateType, useGameStateStore } from "../stores/gameStateStore";
import Carousel from "../components/Carousel";
import { useNavigate } from "react-router-dom";
import { useSimulationService } from "../services/simulationService";
import { useSettingsStore } from "../stores/settingsStore";
import { Coach } from "../models";
import { useFantacalcioApi } from "../services/fantacalcioApi";
import { ROUTES } from "../utils/routes";
import shallow from "zustand/shallow";

export const GamePage = () => {
  const exOffer = useRef(0);
  const timeoutToken = useRef<NodeJS.Timeout | null>(null);
  const reactionTimeoutToken = useRef<NodeJS.Timeout | null>(null);

  const [progress, setProgress] = useState(0);

  const navigate = useNavigate();
  const fantacalcioAPI = useFantacalcioApi();

  const {
    isAuctionStart,
    currentAuctionType,
    currentPlayer,
    currentOffer,
    coachList,
    purchasedPlayerList,
    availablePlayerList,
    setAvailablePlayerList,
    setIsAuctionStart,
    setCoachList,
    setCurrentPlayer,
    setCurrentOffer,
    setPurchasedPlayerList,
  } = useGameStateStore(
    (state) => ({
      isAuctionStart: state.isAuctionStart,
      currentAuctionType: state.currentAuctionType,
      currentPlayer: state.currentPlayer,
      currentOffer: state.currentOffer,
      coachList: state.coachList,
      purchasedPlayerList: state.purchasedPlayerList,
      availablePlayerList: state.availablePlayerList,
      setAvailablePlayerList: state.setAvailablePlayerList,
      setIsAuctionStart: state.setIsAuctionStart,
      setCoachList: state.setCoachList,
      setCurrentPlayer: state.setCurrentPlayer,
      setCurrentOffer: state.setCurrentOffer,
      setPurchasedPlayerList: state.setPurchasedPlayerList,
    }),
    shallow
  );

  const { botSpeed, botAggr, timeoutSeconds } = useSettingsStore();

  const { simulateOffer } = useSimulationService();

  /** Tramite l'useEffect ascolto la const hasInitialized, se ancora il valore è false (quindi solo in questo caso) avvio la funzione di init */
  useEffect(() => {
    !currentAuctionType && navigate("/");
  }, [currentAuctionType]);

  /**Controllo se l'asta è partita e se non esiste un'offerta corrente.
   * In questo la simulazione di acquisto da parte dei coach avversari inizierà. */
  useEffect(() => {
    if (isAuctionStart && !currentOffer) {
      startAnimation();
      initPurchaseTimout();
      simulate();
    }
  }, [isAuctionStart, currentOffer]);

  /**
   * Questo effect controlla il progresso delle nuove offerte.
   * Se la nuova offerta è diversa da quella precedente allora bisogna riavviare il timeout per l'acquisto
   */
  useEffect(() => {
    //Nella prima condizione converto il valore in un boolean e controllo che sia vero
    if (!!currentOffer) {
      //Controllo poi se il progress del timer è uguale a 0 e se il valore dell'offerta corrente è diversa dall'offerta che è stata fatta in precedenza
      if (progress === 0 && currentOffer.offer !== exOffer.current) {
        exOffer.current = currentOffer.offer!;
        initPurchaseTimout();
        //Creo un setTimeot di n secondi. Se il timer scade reinizializzo la sua animazione e concludo l'acquisto
        // l' animazione del timer si avvia
        startAnimation();
        // Altrimenti controllo se il progress a valore 100 e se l'fferta corrente è uguale alla precedente offerta la simulazione dei coach avversari inizia
      } else if (progress === 100 && currentOffer.offer === exOffer.current) {
        simulate();
      }
    }
  }, [currentOffer, progress, exOffer.current]);

  const initPurchaseTimout = () => {
    !!timeoutToken.current && clearTimeout(timeoutToken.current);
    timeoutToken.current = setTimeout(() => {
      const actualCurrentOffer = useGameStateStore.getState().currentOffer;
      resetAnimation();
      if (!actualCurrentOffer) discard();
      else purchase(actualCurrentOffer);
    }, timeoutSeconds * 1000 + 500);
  };

  const getOffers = async () => {
    console.log("send gameState a backend e get offers");
    const newCoachList = await fantacalcioAPI.getOffers(
      availablePlayerList,
      coachList,
      currentPlayer!,
      currentAuctionType!
    );
    setCoachList(newCoachList);
    console.log("prova effect isAuctionStart");
    console.log("prova coachList", coachList);
    initPurchaseTimout();
  };
  /** La funzione fa partire l'asta.
      Se nessun giocatore è selezionato non parte. */
  const startAuction = async () => {
    if (currentPlayer) {
      await getOffers();
      setIsAuctionStart(true);
    }
  };

  /** Con il relunch posso far partire il rilancio dell'offerta.
   * Controllo se l'asta è iniziata in questo caso resetto l'animazione.
   * Setto la mia offerta se il valore di value è null altrimenti l'offerta sarà dei coach avversari */
  const relaunch = (value: OfferStateType) => {
    if (isAuctionStart) {
      resetAnimation();
      setCurrentOffer(value);
    }
  };

  /**La startAnimation serve per far partire l'animazione.
   * Setta il progress di essa a 100 */
  const startAnimation = () => {
    setProgress(100);
  };

  /** La funzione serve per resettare l'animazione.
   * Controlla se timeoutToken ha come valore true.
   * In questo caso elimina il timeOut creato in precedenza.
   * Setta anche il progres dell'animazione a 0 */
  const resetAnimation = () => {
    //"!!timeoutToken && ..."  è equivalente ad un "if(!!timeoutToken) ..."
    setProgress(0);
  };

  /**Con la funzione simulo il rilancio da parte dei coach avversari *
   * Gli avversari che rilanciano vengono scelti in modalità randomica cosi come il tempo di reazione */
  function simulate() {
    if (reactionTimeoutToken.current)
      clearTimeout(reactionTimeoutToken.current);
    const min = 500;
    const timer =
      Math.random() * (timeoutSeconds * (1000 / botSpeed) - min) + min;
    //TODO: descrivere perche abbiamo usato il ts-ignore e da dove deriva shuffle
    // @ts-ignore
    const shuffledCoaches: Coach[] = [...coachList].slice(1).shuffle(); // scelgo randomicamente un coach tranne il mio( slice(1))
    reactionTimeoutToken.current = setTimeout(() => {
      const offer = simulateOffer(shuffledCoaches, currentOffer, botAggr);
      offer && relaunch(offer);
    }, timer);
  }

  const purchase = (newOffer: OfferStateType) => {
    //TODO constrollare se un oggetto estratto con il find modifica anche la lista di appartenenza
    const coach = coachList.find((value) => {
      return value.id === newOffer!.coach!.id;
    })!;
    coach.buyPlayer(currentPlayer!, newOffer!.offer!);
    setCoachList(coachList);
    purchasedPlayerList.push(currentPlayer!);
    const index = availablePlayerList.indexOf(currentPlayer!);
    if (index > -1) {
      availablePlayerList.splice(index, 1);
    }
    newAuction();
  };

  const discard = () => {
    const index = availablePlayerList.indexOf(currentPlayer!);
    availablePlayerList.splice(index, 1);
    purchasedPlayerList.push(currentPlayer!);
    newAuction();
  };

  const newAuction = () => {
    setIsAuctionStart(false);
    setAvailablePlayerList(availablePlayerList);
    setPurchasedPlayerList(purchasedPlayerList);
    setCurrentPlayer(null);
    setCurrentOffer(null);
    exOffer.current = 0;
  };

  const onEndClickHandler = () => {
    navigate("/statistics");
  };

  const handleClipboardCopy = () => {
    const text = JSON.stringify(fantacalcioAPI.getLastSentGameState());
    navigator.clipboard.writeText(text);
  };

  return (
    <Stack direction={"column"} sx={{ color: "black", height: "100%" }}>
      <FantaNavbar
        title="Fantacalcio"
        sx={{ paddingInline: "25px" }}
        items={[ROUTES.settings]}
        isDisabled={isAuctionStart}
      />
      <Timer progress={progress} timeoutSeconds={timeoutSeconds} />
      <Carousel />
      <CoachesGrid />
      <Box
        sx={{
          paddingBottom: "1.6rem",
          width: "100%",
        }}
      >
        <Button onClick={handleClipboardCopy}>copy state</Button>
        <RelaunchButton
          onStartAuction={startAuction}
          onRelaunch={relaunch}
          onEndClick={onEndClickHandler}
          isPlayerSelected={!!currentPlayer}
        />
      </Box>
    </Stack>
  );
};
