import { useEffect, useRef } from "react";
import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./Theme";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HomePage, GamePage, SettingsPage, ErrorPage } from "./pages";
import { useAuctionsStore } from "./stores";
import { useFantacalcioApi } from "./services"
import { StatisticsPage } from "./pages/StatisticsPage";
import { ROUTES } from "./utils/routes";

function App() {
  const setAuctionTypeList = useAuctionsStore(state => state.setAuctionTypeList);
  const hasInitialized = useRef(false);
  const fantaAPI = useFantacalcioApi();

  /** Tramite l'useEffect ascolto la const hasInitialized, se ancora il valore è false (quindi solo in questo caso) avvio la funzione di init */
  useEffect(() => {
    if (!hasInitialized.current) {
      init();
    }
  });

  /**La funzione inizializza l'appiclazione richiedendo al backend gli auctionType disponibili*/
  async function init() {
    hasInitialized.current = true; //Avviato l'init setto hasInitialized a true
    let value = await fantaAPI.getAuctionTypes(); //Assegno a value il valore di ritorno della promise che sarà la lista dei tipi di asta.
    setAuctionTypeList(value); //Setto passando questo valore come parametro al setAuctionTypeList.
  }

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/game" element={<GamePage />} />
            <Route path="/statistics" element={<StatisticsPage />} />
            <Route path="*" element={<ErrorPage />} />
            <Route path={ROUTES.settings.route} element={<SettingsPage />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
