import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { useGameStateStore } from "../stores/gameStateStore";

type TimerProps = {
  progress: number;
  timeoutSeconds: number;
};
export default function Timer({ progress, timeoutSeconds }: TimerProps) {
  const currentOffer = useGameStateStore(state => state.currentOffer);
  return (
    <>
      <Box
        sx={{
          width: "21.125rem",
          display: "flex",
          position: "relative",
          margin: "1rem auto 0.3rem auto",
        }}
      >
        <Box
          sx={{
            height: "calc(3.125rem - 4px)",
            width: "100%",
            borderRadius: "calc(3.125rem / 2)",
            border: "2px solid black",
            background: `
            linear-gradient(to right, rgba(50, 58, 164, 1) 0%,rgba(122, 44, 114, 1) 100%),
            radial-gradient(circle calc(3.125rem / 2) at 0% 50%,transparent,transparent calc(100% - 1px),white calc(3.125rem / 2))
            `,
            backgroundSize: "cover,calc(" + progress + "% + 3.125rem - 1px)",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "0, calc(100% + 3.125rem / 2)",
            backgroundBlendMode: "color",
            transition: progress
              ? `background-size ${timeoutSeconds}s linear`
              : "none",
          }}
        ></Box>
        <Typography
          sx={{
            color: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50% , -50%)",
            webkitTransform: "translate(-50%, -50%)",
            fontWeight: "bold",
            fontSize: "1.5rem",
            textShadow: "0px 2px 9px #991199",
          }}
          color="text.secondary"
          gutterBottom
        >
          {currentOffer?.offer || 0}
        </Typography>
      </Box>
    </>
  );
}
