import { Box, Grid, Stack, Typography } from "@mui/material";
import { useGameStateStore } from "../stores/gameStateStore";

const Carousel = () => {
  const purchasedPlayerList = useGameStateStore(state => state.purchasedPlayerList);

  return (
    <Box>
      <Grid
        sx={{
          display: "flex",
          overflowX: "auto",
          paddingY: "20px",
          padding: "0.5rem",
          //TODO da rivedere l'altezza e il padding
        }}
      >
        {purchasedPlayerList.length > 0 ? (
          purchasedPlayerList.map((player, idx) => {
            return (
              <Stack
                direction={"row"}
                sx={{
                  borderRadius: "10px",
                  backgroundColor: "rgb(234, 234, 234)",
                  display: "flex",
                  margin: "0.5rem",
                }}
                key={idx}
              >
                <Typography
                  sx={{
                    lineHeight: "2.5rem",
                    color: "rgb(210, 83, 198)",
                    paddingInline: "0.5rem",
                  }}
                >
                  {player.level}
                </Typography>
                {/* TODO inserire la funzione per prendere le prime 2 lettere del nome e del cognome */}
                <Typography
                  sx={{
                    lineHeight: "2.5rem",
                    whiteSpace: "nowrap",
                    paddingInline: "0.5rem",
                  }}
                >
                  {player.name
                    .split(" ", 2)
                    .map((word) => word.slice(0, 2))
                    .join(". ")}
                  .
                </Typography>
              </Stack>
            );
          })
        ) : (
          <Typography
            sx={{
              margin: "0.5rem auto",
              lineHeight: "2.5rem",
              color: "red",
            }}
          >
            NESSUN GIOCATORE COMPRATO
          </Typography>
        )}
      </Grid>
    </Box>
  );
};

export default Carousel;
