import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import RowModal from "./RowModal";
import CoachCard from "./CoachCard";
import { Coach } from "../models";

type ModalParams = {
  coach: Coach;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "70vh",
  bgcolor: "background.paper",
  borderRadius: "25px",
  boxShadow: 24,
  padding: "1.5rem",
};

const CoachButton = ({ coach }: ModalParams) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Box onClick={handleOpen}>
        <CoachCard coach={coach} />
      </Box>
      <Modal
        sx={{ bgcolor: "rgba(0, 0, 0, 0.6)" }}
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
      >
        <Box
          sx={{
            ...style,
            width: "18rem",
            height: "28.5rem",
            position: "relative",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.125rem",
              paddingY: "0.6rem",
            }}
            id="Coach Number"
          >
            Coach {coach.id}
          </Typography>
          <RowModal
            player="PLAYER"
            level="LEVEL"
            paid="PRICE"
            color="rgba(79, 89, 243, 1)"
          ></RowModal>

          {coach.playerList.map((player, idx) => (
            <RowModal
              key={idx}
              backGround={idx % 2 === 0 ? `rgba(233, 233, 233, 1)` : `white`}
              player={player}
            ></RowModal>
          ))}
          <RowModal
            player="Strength"
            level={(
              coach.playerList.reduce((p, c) => {
                p += c.level || 0;
                return p;
              }, 0) || 0
            ).toFixed(2)}
            paid={(
              coach.playerList.reduce((p, c) => {
                p += c.paid || 0;
                return p;
              }, 0) || 0
            ).toFixed(2)}
            color="rgba(79, 89, 243, 1)"
          ></RowModal>
          <Box
            sx={{
              display: "flex",
              msFlexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Button
              sx={{
                position: "absolute",
                width: "16.375rem",
                height: "3.125rem",
                borderRadius: "10px",
                background:
                  "linear-gradient(to right, rgba(50, 58, 164, 1) 0%,rgba(122, 44, 114, 1) 100%)",
                bottom: "1.5rem",
                color: "white",
              }}
              onClick={handleClose}
            >
              OK
            </Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default CoachButton;
