import create from "zustand";
import { Auction, Coach, Player } from "../models";
import { GameStateType } from "../services"; 

export type OfferStateType = {
  coach: Coach;
  offer: number;
};

export type GameStateStoreProps = getterProps & setterProps;

type getterProps = {
  isAuctionStart: boolean;
  currentAuctionType: Auction | null;
  currentPlayer: Player | null;
  coachList: Coach[];
  me: Coach | null;
  currentOffer: OfferStateType | null;
  purchasedPlayerList: Player[];
  availablePlayerList: Player[];
  lastSentState: GameStateType | null;
};
const defaultState: getterProps = {
  isAuctionStart: false,
  currentAuctionType: null,
  currentPlayer: null,
  me: null,
  currentOffer: null,
  lastSentState: null,
  availablePlayerList: [],
  coachList: [],
  purchasedPlayerList: [],
};
type setterProps = {
  setIsAuctionStart: (value: boolean) => void;
  setCurrentAuctionType: (value: Auction | null) => void;
  setCurrentPlayer: (value: Player | null) => void;
  setCoachList: (value: Coach[]) => void;
  setMe: (value: Coach) => void;
  setCurrentOffer: (value: OfferStateType | null) => void;
  setPurchasedPlayerList: (value: Player[]) => void;
  setLastSentState: (value: GameStateType) => void;
  setAvailablePlayerList: (value: Player[]) => void;
  reset: () => void;
  getState: () => getterProps;
};
export const useGameStateStore = create<GameStateStoreProps>()((set, get) => ({
  ...defaultState,
  setCurrentAuctionType: (currentAuctionType) =>
    set((_) => ({ currentAuctionType })),
  setIsAuctionStart: (isAuctionStart) => set((_) => ({ isAuctionStart })),
  setCurrentPlayer: (currentPlayer) => set((_) => ({ currentPlayer })),
  setCoachList: (coachList) => set((_) => ({ coachList })),
  setMe: (me) => set((_) => ({ me })),

  setCurrentOffer: (currentOffer) =>
    set((_) => ({
      currentOffer,
    })),

  setAvailablePlayerList: (value) =>
    set((_) => ({ availablePlayerList: value })),

  setPurchasedPlayerList: (purchasedPlayerList) =>
    set((_) => ({ purchasedPlayerList })),

  setLastSentState: (lastSentState) => set((_) => ({ lastSentState })),
  reset: () =>
    set((_) => {
      return {
        ...defaultState,
        availablePlayerList: [],
        purchasedPlayerList: [],
        coachList: [],
      };
    }),

  getState: get,
}));
