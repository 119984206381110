export class Auction {
  coachMoney: number;
  playersNumber: number;
  coachNumber: number;
  slotNumber: number;
  name: string;

  constructor(
    coachMoney: number,
    playersNumber: number,
    coachNumber: number,
    slotNumber: number,
    name: string
  ) {
    this.coachMoney = coachMoney;
    this.playersNumber = playersNumber;
    this.coachNumber = coachNumber;
    this.slotNumber = slotNumber;
    this.name = name;
  }
}
