import * as React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// eslint-disable-next-line no-extend-native
Object.defineProperty(Array.prototype, "shuffle", {
  // TODO: descrivere cosa fa questa funzione
  value: function (): void {
    this.forEach((_: any) => {
      this.sort(() => Math.random() - 0.5);
    });
    return this;
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
