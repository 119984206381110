import axios, { AxiosResponse } from "axios";
import { BASE_URL } from "../utils/constants";

export const useNetwork = () => {
  const post = (
    path: string,
    data: any,
    params?: any
  ): Promise<AxiosResponse> => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URL}/${path}`, data, {
          params,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  const get = (path: string, params?: any): Promise<AxiosResponse> => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URL}/${path}`, {
          params,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  return { post, get };
};
