import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  components: {
    MuiTypography: {
      variants: [
        {
          // @ts-ignore
          props: { variant: "option" },
          style: {
            width: "100%",
            fontWeight: "bold",
            marginTop: "20px",
            color: "rgba(50, 58, 164, 1)",
            textAlign: "left",
          },
        },
      ],
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "inherit",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          "&.Mui-disabled": {
            color: "#8d8d8d",
            filter: "grayscale(1)",
          },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          borderRadius: "30px",
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: "rgba(50, 58, 164, 1)",
        },
      },
    },
  },
  palette: {
    background: {},
    primary: {
      light: "#ffffff",
      main: "#738BFF",
      dark: "#000000",
    },
    error: {
      main: "#D32E2E",
    },
    secondary: {
      main: "#000000",
    },
  },
});
