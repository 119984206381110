import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import { Player } from "../models";

type RowModalProps = {
  player: Player | string;
  level?: number | string;
  paid?: number | string;
  color?: string;
  backGround?: string;
};

const RowModal = ({
  player,
  level: _level,
  paid: _paid,
  color,
  backGround,
}: RowModalProps) => {
  const { name, level, paid } =
    typeof player === "object"
      ? player
      : { name: player, level: _level, paid: _paid };

  return (
    <Grid
      sx={{
        background: { backGround },
        color: { color },
        borderRadius: "0.5rem",
        padding: "0.5rem",
      }}
      container
    >
      <Grid item xs={7}>
        <Typography
          sx={{
            fontSize: "0.75rem",
          }}
        >
          {name}
        </Typography>
      </Grid>
      <Grid
        item
        xs={2}
        sx={{
          padding: 0,
        }}
      >
        <Typography
          align="right"
          sx={{
            fontSize: "0.75rem",
          }}
        >
          {level}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography
          align="right"
          sx={{
            fontSize: "0.75rem",
          }}
        >
          {paid}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default RowModal;
