import { Grid, Stack, TextField } from "@mui/material";
import Button from "@mui/material/Button/Button";
import { render } from "react-dom";
import shallow from "zustand/shallow";
import { OfferStateType, useGameStateStore } from "../stores/gameStateStore";
type RelaunchButtonProps = {
  onStartAuction: () => void;
  onEndClick: () => void;
  onRelaunch: (value: OfferStateType) => void;
  isPlayerSelected: boolean;
};
const RelaunchButton = ({
  onRelaunch,
  onEndClick,
  onStartAuction,
  isPlayerSelected,
}: RelaunchButtonProps) => {
  const { isAuctionStart, currentOffer, me } = useGameStateStore(
    (state) => ({
      isAuctionStart: state.isAuctionStart,
      currentOffer: state.currentOffer,
      me: state.me,
    }),
    shallow
  );

  function onClick(increment: number) {
    const relaunch = (currentOffer?.offer || 0) + increment;
    onRelaunch({
      coach: me!,
      offer: relaunch,
    });
  }

  function validRelaunch(increment: number): boolean {
    if (!me) return false;
    const relaunch = (currentOffer?.offer || 0) + increment;
    return (
      relaunch > (currentOffer?.offer || 0) && // la mia offerta per essere valida deve essere maggiore dell'oferta corrente
      (!currentOffer || currentOffer?.coach?.id !== me.id) && // non esiste l'offerta corrente o l'offerta corrente non è stata fatta da me (controllo l'id)
      me.budget - relaunch >= me.slot - 1 && // controllo se il budget è maggiore degli slot (faccio questo per permettere di prendere tutti players che gli slot mi permettono di acquistare)
      me.slot > 0
    ); // Posso fare un offerta finchè gli slot a disposizione sono maggiori di 0
  }

  /** La funzione permette di piazzare un'offerta.
   *  Controllo se il valore dell offerta è maggiore dell offerta corrente.
   *  In questo caso setto la mia offerta*/

  function offerButton(increment: number) {
    const isDisabled = !validRelaunch(increment);
    return (
      <Button
        disabled={isDisabled}
        onClick={() => onClick(increment)}
        sx={{
          background:
            "linear-gradient(to right, rgba(50, 58, 164, 1) 0%,rgba(122, 44, 114, 1) 100%)",
          color: "white",
          borderRadius: "10px",
          minWidth: 0,
          width: "100%",
          height: "3.125rem",

          fontWeight: "bold",
          fontSize: "1.125rem",
        }}
      >
        +{increment}
      </Button>
    );
  }

  const renderConclusionButton = () => {
    return (
      <Button
        onClick={onEndClick}
        sx={{
          background:
            "linear-gradient(to right, rgba(50, 58, 164, 1) 0%,rgba(122, 44, 114, 1) 100%)",
          color: "white",
          borderRadius: "10px",
          minWidth: 0,
          width: "100%",
          height: "3.125rem",

          fontWeight: "bold",
          fontSize: "1.125rem",
        }}
      >
        Concludi
      </Button>
    );
  };

  return (
    <Stack direction={"column"} alignItems={"center"}>
      <Stack
        direction={"column"}
        justifyContent="flex-end"
        alignItems={"center"}
        sx={{
          width: "21.125rem",
        }}
        gap={1}
      >
        <Grid
          container
          sx={{
            height: "3.125rem",
          }}
          alignItems="flex-end"
        >
          {!isAuctionStart ? (
            <Stack gap={1} direction="row" width={"100%"}>
              {me?.slot === 0 && renderConclusionButton()}
              <Button
                disabled={!isPlayerSelected}
                sx={{
                  background:
                    "linear-gradient(to right, rgba(50, 58, 164, 1) 0%,rgba(122, 44, 114, 1) 100%)",
                  color: "white",
                  borderRadius: "10px",

                  width: "100%",
                  height: "3.125rem",

                  fontWeight: "bold",
                  fontSize: "1.125rem",
                }}
                onClick={onStartAuction}
              >
                Avvia
              </Button>
            </Stack>
          ) : (
            <Grid item xs={12}>
              <Stack
                gap={1}
                direction={"row-reverse"}
                justifyContent={"flex-end"}
              >
                {offerButton(1)}
                {offerButton(5)}
                {offerButton(10)}
              </Stack>
            </Grid>
          )}
        </Grid>
      </Stack>
    </Stack>
  );
};

export default RelaunchButton;
