import React, { useEffect, useRef } from "react";
import FantaNavbar from "./../components/FantaNavbar";
import BoxAuction from "./../components/BoxAuction";
import { Box, Skeleton, Stack } from "@mui/material";
import { useAuctionsStore, useGameStateStore } from "../stores";
import { useNavigate } from "react-router-dom";
import { Auction } from "../models/auction";
import { ROUTES } from "../utils/routes";
import { useFantacalcioApi } from "../services";
import { Coach } from "../models";
import shallow from "zustand/shallow";

export const HomePage = () => {
  const hasInitialized = useRef(false);
  const auctionTypeList = useAuctionsStore((state) => state.auctionTypeList);
  const {
    setCurrentAuctionType,
    setCoachList,
    setAvailablePlayerList,
    setMe,
    reset,
    getState,
    currentAuctionType,
  } = useGameStateStore(
    (state) => ({
      setCurrentAuctionType: state.setCurrentAuctionType,
      setCoachList: state.setCoachList,
      setAvailablePlayerList: state.setAvailablePlayerList,
      setMe: state.setMe,
      reset: state.reset,
      getState: state.getState,
      currentAuctionType: state.currentAuctionType,
    }),
    shallow
  );

  const fantacalcioAPI = useFantacalcioApi();
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasInitialized.current) {
      hasInitialized.current = true;
      console.log("reset");
      reset();
      console.log(getState());
    }
  }, [hasInitialized]);

  /** Questa funzione deve essere eseguita alla scelta della tipologia di asta.
      La scelta della tipologia d'asta avviene con il click sul bottone corrispondente.
      La tipologia d'asta scelta viene salvata in memoria e l'utente viene reindirizzato nella pagina "GamePage".*/
  const onClickAuctionType = async (auctionType: Auction) => {
    setCurrentAuctionType(auctionType);
  };

  async function startGame() {
    const value = await fantacalcioAPI.getPlayerList(); //Assegno il valore di ritorno della promise che sarà la lista dei players.
    setAvailablePlayerList(value); //Setto i giocatori disponibili passando come parametro value
    const coachList = generateCoachList(currentAuctionType!); //Assegno il valore di ritorno della generateCoachList dentro coachList
    const me = coachList[0];
    setCoachList(coachList); //setto la coachList
    setMe(me); // Il coach che rappresenterà l'utente sarà il primo della lista
    navigate("/game");
  }

  useEffect(() => {
    if (currentAuctionType != null) {
      startGame();
    }
  }, [currentAuctionType]);

  /** Creo la funzione per generare la lista dei Coach in base all'auctionType.
   * Infatti utilizzo il valore del coachNumber come lunghezza del ciclo for.
   * In questo modo creo un numero di coach avversari.
   * Creo il nuovo Coach e lo pusho nella coachList.
   * All' interno di ogni oggetto coach imposto il budget e gli slot disponibili. */
  function generateCoachList(auctionType: Auction): Coach[] {
    const coachList: Coach[] = [];
    for (let index = 1; index <= auctionType!.coachNumber; index++) {
      coachList.push(
        new Coach(index, auctionType!.coachMoney, auctionType!.slotNumber)
      );
    }
    return coachList;
  }

  return (
    <Box
      sx={{
        background:
          "linear-gradient(to bottom, rgba(50, 58, 164, 1) 0%,rgba(122, 44, 114, 1) 100%)",
        padding: "5px 30px",
        color: "white",
        height: "100%",
      }}
    >
      <FantaNavbar
        title={"Fantacalcio"}
        showBack={false}
        items={Object.values(ROUTES)}
      />
      <Box
        className="BoxDescription"
        component="span"
        sx={{ p: 2, border: "" }}
      >
        <p>
          Is simply dummy text of the printing and typesetting industry. Lorem
          Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled it
          to make a type specimen book.
        </p>
      </Box>
      <Box
        className="backgroundText"
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {auctionTypeList.length > 0 ? (
          auctionTypeList.map((auction, idx) => (
            <BoxAuction
              key={idx}
              auction={auction}
              onClick={() => onClickAuctionType(auction)} // Associo ad ogni bottone la funzione onClickAuvtionTyper passandogli come argomento l'auctionType che sto attualmente scorrendo
            />
          ))
        ) : (
          <Stack gap={"15px"}>
            <Skeleton variant="rounded" width={"100%"} height={120} />
            <Skeleton variant="rounded" width={"100%"} height={120} />
            <Skeleton variant="rounded" width={"100%"} height={120} />
          </Stack>
        )}
      </Box>
    </Box>
  );
};
