import { Slider, SliderProps, Stack, Typography } from "@mui/material"

export type SliderFieldprops = {
    label: string,
    value: number,
    setValue: (newValue: number) => void,
    min?: number,
    max?: number,
    step?: number
    sliderProps?: SliderProps
}

export const SliderField = (props: SliderFieldprops) => {

    const handleChange = (e: any) => {
        props.setValue(e.target.value)
    }

    return (
        <Stack sx={{ width: "100%" }}>
            <Typography
                // @ts-ignore         
                variant="option"
            >
                {`${props.label}: ${props.value}`}
            </Typography>
            <Slider
                {...props.sliderProps}
                aria-label="Default"
                valueLabelDisplay="auto"
                min={props.min}
                max={props.max}
                step={props.step}
                value={props.value}
                onChange={handleChange}
            />
        </Stack>
    )
}