import { Stack } from "@mui/material";
import FantaNavbar from "../components/FantaNavbar";
import { BotAggrOption } from "./settings/BotAggrOption";
import { BotSpeedOption } from "./settings/BotSpeedOption";
import { TimoutOption } from "./settings/TimeoutOption";

export const SettingsPage = () => {


  return (
    <div>
      <FantaNavbar
        title={"Settings"}
        sx={{ paddingInline: "25px" }}
      />
      <Stack sx={{ width: "90vw", margin: "0 auto" }}>
        <BotSpeedOption />
        <BotAggrOption />
        <TimoutOption />
      </Stack>
    </div>
  );
};
