import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ShuffleSharpIcon from "@mui/icons-material/ShuffleSharp";
import { CommonProps } from "@mui/material/OverridableComponent";
import { IconButton, Stack, SxProps, Theme } from "@mui/material";
import { useGameStateStore } from "../stores/gameStateStore";
import { Player } from "../models";
import shallow from "zustand/shallow";

type SearchParams = {
  children?: JSX.Element;
  sx?: SxProps<Theme>;
} & CommonProps;

export default function Search({ children, ...rest }: SearchParams) {
  const {
    isAuctionStart,
    currentPlayer,
    setCurrentPlayer,
    availablePlayerList,
  } = useGameStateStore(state => (
    {
      isAuctionStart: state.isAuctionStart,
      currentPlayer: state.currentPlayer,
      setCurrentPlayer: state.setCurrentPlayer,
      availablePlayerList: state.availablePlayerList
    }
  ), shallow);
  
  function getRandomItem() {
    const randomIndex = Math.floor(Math.random() * availablePlayerList.length);

    const item = availablePlayerList[randomIndex];
    setCurrentPlayer(item);
  }

  function onRandomPlayerClick() {
    getRandomItem();
  }

  function onChange(e: any, value: Player | null, reason: string) {
    setCurrentPlayer(value);
  }

  return (
    <Box
      {...rest}
      sx={{
        width: "100%",
        display: "flex",

        justifyContent: "center",
        borderRadius: "25px",
        background:
          "linear-gradient(to right, rgba(50, 58, 164, 1) 0%,rgba(122, 44, 114, 1) 100%)",
        ...rest.sx,
      }}
    >
      <Stack
        direction={"row"}
        sx={{
          backgroundColor: "rgba(255, 251, 251, 0.8)",
          marginTop: "30px",
          width: "21.125rem",
          height: "3.125rem",
          borderRadius: "10px",
        }}
      >
        <Autocomplete
          disabled={isAuctionStart}
          sx={{
            color: "black",
            width: "100%",
            marginTop: "15px",
            marginY: "auto",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "0",
            },
            "& .MuiOutlinedInput-root": {
              padding: "0px 0px 0px 10px",
            },
          }}
          options={availablePlayerList}
          value={currentPlayer}
          onChange={onChange}
          autoHighlight
          popupIcon={null}
          getOptionLabel={(option) => `(${option.level}) ${option.name} `}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              {/*               <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.id.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.id.toLowerCase()}.png 2x`}
                alt=""
              /> */}
              ({option.level}) {option.name}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              className="border-0"
              {...params}
              sx={{
                width: "100%",
              }}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            disabled={isAuctionStart}
            sx={{
              size: "large",
              color: "styleNavbar.colorText",
              paddingLeft: "0",
            }}
            onClick={() => {
              onRandomPlayerClick();
            }}
          >
            <ShuffleSharpIcon
              sx={{
                paddingX: "15px",
              }}
              color="secondary"
            ></ShuffleSharpIcon>
          </IconButton>
        </Box>
      </Stack>
      <Box>{children}</Box>
    </Box>
  );
}
