export type RouteItem = {
  label: string;
  route: string;
};

type Routes = { [key: string]: RouteItem };
export const ROUTES: Routes = {
  settings: {
    label: "Settings",
    route: "/settings",
  },
};
