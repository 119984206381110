import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import shallow from "zustand/shallow";
import FantaNavbar from "../components/FantaNavbar";
import { Coach, Player } from "../models";
import { useFantacalcioApi, useSimulationService } from "../services";
import { OfferStateType, useGameStateStore, useSettingsStore } from "../stores";

export const StatisticsPage = () => {
    const navigate = useNavigate();

    const [isOnSimulation, setisOnSimulation] = useState(true)

    const hasInitialized = useRef(false)

    const {
        currentAuctionType,
        currentPlayer,
        coachList,
        purchasedPlayerList,
        availablePlayerList,
        setAvailablePlayerList,
        setCoachList,
        setPurchasedPlayerList,
        getState,
      } = useGameStateStore(state => ({
        currentAuctionType: state.currentAuctionType,
        currentPlayer: state.currentPlayer,
        coachList: state.coachList,
        purchasedPlayerList: state.purchasedPlayerList,
        availablePlayerList: state.availablePlayerList,
        setAvailablePlayerList: state.setAvailablePlayerList,
        setCoachList: state.setCoachList,
        setPurchasedPlayerList: state.setPurchasedPlayerList,
        getState: state.getState
      }), shallow);

    const fantacalcioAPI = useFantacalcioApi();

    const { simulateOffer } = useSimulationService()

    const { botAggr } = useSettingsStore()

    useEffect(() => {
        if(!hasInitialized.current) {
            init();
        }
        hasInitialized.current = true;
    }, [hasInitialized.current])

    function getRandomItem() {
        const randomIndex = Math.floor(Math.random() * availablePlayerList.length);
        return availablePlayerList[randomIndex];
    }

    const getOffers = async (player:Player) => {
        return await fantacalcioAPI.getOffers(
            availablePlayerList,
            coachList,
            player,
            currentAuctionType!
        );
    };

    function simulate(coaches: Coach[], offer: OfferStateType | null) {
        // @ts-ignore
        const shuffledCoaches: Coach[] = [...coaches].slice(1).shuffle(); // scelgo randomicamente un coach tranne il mio( slice(1))
        return simulateOffer(shuffledCoaches, offer, botAggr)
    }
    

    const purchase = (offer: OfferStateType, player: Player) => {
        //TODO constrollare se un oggetto estratto con il find modifica anche la lista di appartenenza
        const coach = coachList.find((value) => {
          return value.id === offer.coach!.id;
        })!;
        coach.buyPlayer(player, offer.offer);
        setCoachList(coachList);
        purchasedPlayerList.push(player);
        const index = availablePlayerList.indexOf(player);
        if (index > -1) {
          availablePlayerList.splice(index, 1);
        }
    };

    const discard = () => {
    const index = availablePlayerList.indexOf(currentPlayer!);
    availablePlayerList.splice(index, 1);
    purchasedPlayerList.push(currentPlayer!);
    };

    const newAuction = () => {
        setAvailablePlayerList(availablePlayerList);
        setPurchasedPlayerList(purchasedPlayerList);
      };
    
    const init = async () => {
        let totalSlot:number = 0;
        for(let i=1; i<coachList.length; i++) {
            totalSlot += coachList[i].slot;
        }
        console.log(getState())
        if(availablePlayerList.length !== 0 && totalSlot !== 0) {
            const player = getRandomItem();
            console.log(player)
            const coaches = await getOffers(player);
            let flag = true
            let validOffer = null;
            while(flag) {
                const offer = simulate(coaches, validOffer);
                if(offer) {
                    validOffer = offer;
                };
                console.log(offer)
                flag = !!offer
            }
            if (!!validOffer) {
                purchase(validOffer, player)
            } else {discard()}
            newAuction();
            hasInitialized.current = false;
        } else {setisOnSimulation(false); console.log(getState(), availablePlayerList)}
        // prendere i giocatori rimasti e acquistati, situazione coaches
        // estrarre il giocatore
        // comunichiamo con backend e riceviamo le offerte dei bot
        // un coach acquista il giocatore e viene aggiornata la situazione coaches e player
        // ricominciare il ciclo fino a quando hai player disponibili e coach con slot liberi
    }

    return (
        <div>
            <FantaNavbar
                title={"Statistics"}
                showBack={false}
                sx={{ paddingInline: "25px" }}
            />
            {isOnSimulation && <Stack
                sx={{
                    width: "100%",
                    height: "200px",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        padding: "20px",
                        fontWeight: "bold",
                        color: "rgba(50, 58, 164, 1)",
                    }}
                >
                    Attendere fine simulazione
                </Typography>
                <CircularProgress
                    sx={{
                        color: "rgb(122, 43, 114)",
                    }}
                    size={60}
                />
            </Stack>}
            <Stack
                alignItems={"flex-end"}
                direction={"row"}
                sx={{
                    height: "70vh",
                }}
            >
                <Button
                    sx={{
                        margin: "20px auto",
                        background:
                            "linear-gradient(to right, rgba(50, 58, 164, 1) 0%,rgba(122, 44, 114, 1) 100%)",
                        color: "white",
                        borderRadius: "10px",
                        width: "80%",
                        height: "3.125rem",
                        fontWeight: "bold",
                        fontSize: "1.125rem",
                    }}
                    onClick={() => navigate("/")}
                >
                    Ritorna alla Home
                </Button>
            </Stack>
        </div>
    );
};

