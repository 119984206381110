import { OfferStateType } from "../stores/gameStateStore";
import { Player } from "./player";

export type PlayerPriceCap = {
  coach?: Coach;
  priceCap: number;
};

export class Coach {
  id: number;
  playerList: Player[] = [];
  budget: number;
  slot: number;
  playersPriceCap: PlayerPriceCap[] = [];

  constructor(id: number, budget: number, slot: number) {
    this.id = id;
    this.budget = budget;
    this.slot = slot;
  }

  buyPlayer(player: Player, price: number): void {
    player.paid = price; // setto il costo pagato per questo giocatore
    this.playerList.push(player);
    this.budget -= price;
    this.slot -= 1;
  }

  getPriceCapWhere(currentOffer: OfferStateType | null) {
    return this.playersPriceCap.find((value) => {
      //console.log(value, "value");
      return (
        (!currentOffer && !value.coach) ||
        currentOffer?.coach.id === value.coach?.id
      );
    });
  }
}
