import {
    Box,
    Checkbox,
    FormControlLabel,
    Slider,
    Stack,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { SliderField } from "../../components/input";
import { useSettingsStore } from "../../stores";
import { isDebug } from "../../utils/constants";
export const BotSpeedOption = () => {

    const { botSpeed, setBotSpeed } = useSettingsStore();
    const [isBotspeed1000, setIsBotspeed1000] = useState(false);
    const [tempBotSpeed, setTempBotSpeed] = useState(botSpeed);

    useEffect(() => console.log(botSpeed), [botSpeed])
    useEffect(() => {
        if (!isBotspeed1000) {
            setBotSpeed(tempBotSpeed);
        } else setBotSpeed(1000);
    }, [tempBotSpeed, isBotspeed1000]);

    const handleChangeIsBotspeed1000 = (event: any) => {
        setIsBotspeed1000(event.target.checked);
    };

    const handleChangeSlider = (event: any) => {
        setTempBotSpeed(event.target.value);
    };

    return (<>
        <Stack
            direction={"row"}
            sx={{
                width: "100%",
                margin: "20px auto",
                alignItems: "center",
            }}
        >
            <SliderField
                label="Bot speed"
                value={botSpeed}
                setValue={setTempBotSpeed}
                min={1}
                max={10}
                step={1}
                sliderProps={{
                    disabled: isBotspeed1000,
                    marks: true,
                }}
            />
            {isDebug && (
                <FormControlLabel
                    sx={{
                        margin: "auto",
                    }}
                    control={
                        <Checkbox
                            sx={{ color: "rgba(50, 58, 164, 1)" }}
                            checked={isBotspeed1000}
                            onChange={handleChangeIsBotspeed1000}
                        />
                    }
                    label=""
                />
            )}
        </Stack>
    </>)
}