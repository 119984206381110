import { Auction } from "../models/auction";
import { PlayerPriceCap, Coach, Player } from "../models";
import { useNetwork } from "./network";
import { useGameStateStore } from "../stores/gameStateStore";
import shallow from "zustand/shallow";

export type GameStateType = {
  settings: string;
  available_players: string[];
  coaches_state: number[][];
  player: string;
};

export const useFantacalcioApi = () => {
  const { post, get } = useNetwork();
  const { lastSentState, currentAuctionType, setLastSentState } = useGameStateStore(
    (state) => ({
      lastSentState: state.lastSentState,
      setLastSentState: state.setLastSentState,
      currentAuctionType: state.currentAuctionType
    }),
    shallow
  );

  /** La funzione getAuctionTypes farà una chiamata al backend chiedendo la lista delle auctionType disponibili */
  const getAuctionTypes = async () => {
    const Auctions: Auction[] = [];
    for (const auctionName of ["Small", "Medium"]) {
      const res = await get("get_details/", {
        settings: auctionName.toLowerCase(),
      });
      const value: {
        budget: number;
        final_players_state: number[];
        highest_level_cardinality: number;
        initial_players_state: number[];
        n_coaches: number;
        n_levels: number;
        n_players: number;
        n_slots: number;
      } = res.data;
      Auctions.push(
        new Auction(
          value.budget,
          value.n_players,
          value.n_coaches,
          value.n_slots,
          auctionName
        )
      );
    }
    console.log(Auctions);
    return Auctions;
  };

  /** La funzione getPlayerList farà una chiamata al backend chiedendo la lista dei giocatori disponibili */
  const getPlayerList = async () => {
    const res = await get("get_players/", { settings: currentAuctionType?.name.toLowerCase() });
    const parsed = res.data;
    const Players: Player[] = Object.entries<{
      name: string;
      level: number;
      role: number;
    }>(parsed).map((obj) => {
      const key = obj[0],
        value = obj[1];
      return new Player(parseInt(key), value.name, value.level);
    });
    return Players;
  };

  const getOffers = async (
    availablePlayerList: Player[],
    coachList: Coach[],
    currentPlayer: Player,
    auctionType: Auction
  ): Promise<Coach[]> => {
    const data = convertGameState(
      availablePlayerList,
      coachList,
      currentPlayer,
      auctionType
    );
    setLastSentState(data);
    const res = await post("offers/", data);
    Object.entries<[number]>(res.data).map((obj) => {
      const coachIdx = parseInt(obj[0]);
      const coachId = coachIdx + 1;
      const coachCopy = [...coachList].filter((c) => c.id !== coachId);
      const values = obj[1];
      const playerPriceCap: PlayerPriceCap[] = [
        { coach: undefined, priceCap: values[coachIdx] > 0 ? 1 : 0 }, //il valore alla posizione del coach attuale è quello che offrirebbe se nessun altro ha fatto alcuna offerta
      ];
      coachCopy.forEach((c) => {
        playerPriceCap.push({ coach: c, priceCap: values[c.id - 1] });
      });

      coachList.find((c) => c.id === coachId)!.playersPriceCap = playerPriceCap;
    });
    console.log(coachList);
    return coachList;
    //TODO: commentare questa funzione
    //response = { '1': ["":0, "2":1, "3":3, "4":5], '2': ["":0, "1":1, "3":3, "4":5], '3': [0, 1, 3, 5], '4': [0, 1, 3, 5]}
  };

  const convertGameState = (
    availablePlayerList: Player[],
    coachList: Coach[],
    currentPlayer: Player,
    auctionType: Auction
  ): GameStateType => {
    const data = {
      settings: auctionType.name.toLowerCase(),
      available_players: availablePlayerList
        .filter((p) => p.id !== currentPlayer.id)
        .map((p) => p.id.toString()),
      coaches_state: coachList.map((c) => {
        const purchasedPlayer = c.playerList.map((p) => p.id);
        return [...purchasedPlayer, c.budget];
      }),
      player: currentPlayer.id.toString(),
    };

    return data;
  };

  const getLastSentGameState = () => {
    return lastSentState;
  };

  return {
    getAuctionTypes,
    getPlayerList,
    getOffers,
    convertGameState,
    getLastSentGameState,
  };
};
